<template>
  <div>
    <b-card>
      <el-form
        ref="filter"
        label-position="top"
        label-width="100px"
        :model="filter"
        @submit="getItems"
      >
        <el-row
          class="mb-2"
          :gutter="20"
        >
          <el-col
            :md="8"
            :lg="6"
          >
            <label label="category_id">Категория </label>
            <v-select
              id="category_id"
              v-model="filter.parent0_category_id"
              :options="categoriesLevel0"
              :reduce="(option) => option.id"
              class="w-100"
              style="width: 100%"
              placeholder="Категория"
              label="name_uz"
              @input="parent0change($event)"
            />
          </el-col>
          <el-col
            v-if="filter.parent0_category_id"
            :md="8"
            :lg="6"
          >
            <label label="category_id1">Подкатегория </label>
            <v-select
              id="category_id1"
              v-model="filter.parent1_category_id"
              :options="categoriesLevel1"
              :reduce="(option) => option.id"
              class="w-100"
              label="name_uz"
              placeholder="Подкатегория"
              @input="parent1change($event)"
            />
          </el-col>
          <el-col
            v-if="filter.parent1_category_id"
            :md="8"
            :lg="6"
          >
            <label label="category_id2">Номенклатура </label>
            <v-select
              id="category_id2"
              v-model="filter.parent2_category_id"
              :options="categoriesLevel2"
              :reduce="(option) => option.id"
              placeholder="Номенклатура"
              class="w-100"
              label="name_uz"
              @input="parent2change($event)"
            />
          </el-col>
          <el-col
            :md="8"
            :lg="6"
          >
            <label label="v-phone-1">Nomi bo'yicha qidiruv</label>
            <b-form-input
              id="v-phone-1"
              v-model="filter.name_uz"
              placeholder="Nomi bo'yicha qidiruv"
              @input="getItems"
            />
          </el-col>
          <el-col
            :md="8"
            :lg="6"
          >
            <label label="v-phone-1">Bazada mavjudligi bo'yicha</label>
            <b-form-checkbox
              v-model="filter.has_in_warehouse"
              :value="1"
              :unchecked-value="0"
              @input="onHasWHSelect"
            >
              Bazada mavjud
            </b-form-checkbox>
          </el-col>
        </el-row>
      </el-form>

      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="loading"
      >

        <el-table
          v-if="!loading"
          ref="treeTable"
          :data="warehouseProducts"
          row-key="id"
          border
        >
          <el-table-column
            type="index"
            :index="indexMethod"
          />
          <el-table-column label="Mahsulot nomi">
            <template slot-scope="scope">
              <span class="c-pointer fs16">
                <span>
                  {{
                    [
                      scope.row.parent0_name_uz,
                      scope.row.parent1_name_uz,
                      scope.row.parent2_name_uz,
                    ].join("/ ")
                  }}
                </span>
                <br>
                <b
                  class="link"
                  @click=" $router.push({
                    name: 'ProductInfo',
                    params: { id: scope.row.id },
                  })"
                >
                  <i class="el-icon-star-off" />
                  {{ scope.row.name_uz }}
                </b>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Foto">
            <template slot-scope="scope">
              <div
                v-if="scope.row.photos && scope.row.photos.length"
                class="d-flex flex-wrap"
              >
                <div
                  v-for="photo in scope.row.photos"
                  :key="photo.id"
                  class="mr-1"
                >
                  <el-image
                    fluid
                    class="p_table_photo"
                    :src="storageUrl + photo.url"
                    :preview-src-list="scope.row.photos.map(p => storageUrl + p.url)"
                  />
                </div>
              </div>
              <span
                v-else
                class="text-muted"
              >Foto yo'q</span>
              <!--  -->
              <b-button
                class="d-block mt-1"
                size="sm"
                variant="outline-info mt-1"
                @click="openImageModal(scope.row)"
              >
                <!--  -->
                Yuklash
              </b-button>
            </template>
          </el-table-column>
        </el-table>
        <div
          v-else
          style="height: 550px"
        />
      </b-overlay>
      <!--  -->
      <el-pagination
        background
        :total="filter.total"
        :page-size="1 * filter.per_page"
        layout="prev, pager, next"
        class="mt-3"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <!--  -->

      <ImageUpload
        v-if="imageModal"
        :model="product"
        :uploaded-photos="product.photos"
        :product-name="product.parent2_name_uz + ' ' + product.name_uz"
        @close="closeUpload"
      />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    vSelect,
    ImageUpload: () => import('@/components/modals/imagesUplad.vue'),
    WarehouseProductInfo: () => import('@/views/warehouse/products/warehouseProductInfo.vue'),
  },
  props: {},
  data() {
    return {
      showProductModal: false,
      imageModal: false,
      page: 1,
      loading: false,
      items: [],
      product: null,
      warehouseProducts: [],
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      pagination: {
        page: 1,
        per_page: 50,
        total: 0,
      },
      filter: {
        per_page: 50,
        page: 1,
        name_uz: null,
        category_id: null,
        relations: 'photos',
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        category_name: '',
        has_in_warehouse: 1,
      },
      selected: null,
      pCategoryId: null,
    }
  },
  computed: {
    ...mapGetters({
      product_categories: 'productCategory/GET_ITEMS',
    }),
    user() {
      return this.$store.getters['auth/USER']
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  watch: {},
  mounted() {
    this.getCategoriesByLevel(0)

    if (this.$route.query.parent0_category_id) {
      this.filter.parent0_category_id = Number(
        this.$route.query.parent0_category_id,
      )
    }
    if (this.$route.query.page) {
      this.filter.page = Number(this.$route.query.page)
      this.page = Number(this.$route.query.page)
      this.pagination.page = Number(this.$route.query.page)
    }
    if (this.$route.query.parent1_category_id) {
      this.getCategoriesByLevel(
        2,
        Number(this.$route.query.parent1_category_id),
      )
      this.filter.parent1_category_id = Number(
        this.$route.query.parent1_category_id,
      )
    }
    if (this.$route.query.parent2_category_id) {
      this.filter.parent2_category_id = Number(
        this.$route.query.parent2_category_id,
      )
      this.getCategoriesByLevel(
        3,
        Number(this.$route.query.parent2_category_id),
      )
    }
  },
  methods: {
    ...mapActions({
      getCategories: 'productCategory/index',
      modelsWithParents: 'productCategory/modelsWithParents',
    }),
    onHasWHSelect() {
      this.getItems()
    },
    async getItems() {
      this.selected = null
      this.loading = true
      await this.modelsWithParents(this.filter).then(({ data }) => {
        this.warehouseProducts = data.data
        this.filter.total = data.total
        this.filter.page = data.current_page
      })

      this.loading = false
    },
    indexMethod(index) {
      return (
        (Number(this.filter.page) - 1) * Number(this.filter.per_page)
        + index
        + 1
      )
    },
    handleCurrentChange(val) {
      this.filter.page = val
      this.changeQuery('page', val)
      this.getItems()
    },
    handleSizeChange(val) {
      this.filter.per_page = val
      this.getItems()
    },
    clearFilter() {
      this.filter.category_name = ''
      this.filter.category_id = null
      this.getItems()
    },
    async parent0change(event) {
      this.filter.parent1_category_id = null
      this.filter.parent2_category_id = null
      this.filter.page = 1
      this.changeQuery('parent0_category_id', event)
      this.changeQuery('parent1_category_id', null)
      this.changeQuery('parent2_category_id', null)

      await this.getCategoriesByLevel(1, event)
    },
    parent1change(event) {
      this.filter.parent2_category_id = null
      this.filter.page = 1
      this.getCategoriesByLevel(2, event)
      this.changeQuery('parent1_category_id', event)
    },
    parent2change(event) {
      this.filter.page = 1
      this.changeQuery('parent2_category_id', event)
      this.getItems()
    },
    getCategoriesByLevel(level, parentId = null) {
      this.loading = true
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then(res => {
          this[`categoriesLevel${level}`] = res.data.data
        })
        .finally(() => {
          this.getItems()
          this.loading = false
        })
    },
    openImageModal(product) {
      this.imageModal = true
      this.product = product
    },
    closeUpload() {
      this.imageModal = false
      this.getItems()
    },
  },
}
</script>

<style>
.node-title {
  font-size: 18px;
}

.el-table .warning-row {
  background: #fffcf6;
}

.el-table .success-row {
  background: #e9fff2;
}

.menu-bar-container {
  position: absolute !important;
}

.p_table_photo {
  width: 80px;
  border: 1px solid #c4beff;
  border-radius: 5px;
}
</style>
